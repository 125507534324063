// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-adatvedelmi-tajekoztato-tsx": () => import("./../../../src/pages/adatvedelmi-tajekoztato.tsx" /* webpackChunkName: "component---src-pages-adatvedelmi-tajekoztato-tsx" */),
  "component---src-pages-blog-csatater-korzetek-tsx": () => import("./../../../src/pages/blog/csatater-korzetek.tsx" /* webpackChunkName: "component---src-pages-blog-csatater-korzetek-tsx" */),
  "component---src-pages-blog-egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas-tsx": () => import("./../../../src/pages/blog/egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas.tsx" /* webpackChunkName: "component---src-pages-blog-egyeni-korzetekben-fog-eldolni-a-2022-es-valasztas-tsx" */),
  "component---src-pages-blog-ellenzeki-gyozelem-vagy-fidesz-ketharmad-tsx": () => import("./../../../src/pages/blog/ellenzeki-gyozelem-vagy-fidesz-ketharmad.tsx" /* webpackChunkName: "component---src-pages-blog-ellenzeki-gyozelem-vagy-fidesz-ketharmad-tsx" */),
  "component---src-pages-blog-valasztasi-rendszer-tsx": () => import("./../../../src/pages/blog/valasztasi-rendszer.tsx" /* webpackChunkName: "component---src-pages-blog-valasztasi-rendszer-tsx" */),
  "component---src-pages-flex-tsx": () => import("./../../../src/pages/flex.tsx" /* webpackChunkName: "component---src-pages-flex-tsx" */),
  "component---src-pages-geo-tsx": () => import("./../../../src/pages/geo.tsx" /* webpackChunkName: "component---src-pages-geo-tsx" */),
  "component---src-pages-impresszum-tsx": () => import("./../../../src/pages/impresszum.tsx" /* webpackChunkName: "component---src-pages-impresszum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kampanyfonok-tsx": () => import("./../../../src/pages/kampanyfonok.tsx" /* webpackChunkName: "component---src-pages-kampanyfonok-tsx" */),
  "component---src-pages-oevks-tsx": () => import("./../../../src/pages/oevks.tsx" /* webpackChunkName: "component---src-pages-oevks-tsx" */),
  "component---src-pages-rolunk-tsx": () => import("./../../../src/pages/rolunk.tsx" /* webpackChunkName: "component---src-pages-rolunk-tsx" */)
}

